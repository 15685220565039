// Nav - show/hide border
(() => {
  const nav = document.getElementById('nav');
  if (!nav) {
    return;
  }

  const showNavBorder = nav.querySelector('.js-show-nav-border');
  if (showNavBorder && showNavBorder.textContent === 'false') {
    nav.style.border = 'none';
  }
})();

//show-hide text below main search form
//advanced Webflow Symbol override (legacy compatibility)
(() => {
  const searchComponentSubheadings = document.querySelectorAll('.js-show-subheading');
  if (!searchComponentSubheadings.length) {
    return;
  }

  searchComponentSubheadings.forEach(function (subheading) {
    const shouldHide = subheading.textContent === 'true';
    if (!shouldHide) {
      return;
    }

    const searchComponent = subheading.closest('.js-domain-search-component');
    if (!searchComponent) {
      return;
    }

    const searchComponentTextContainer = searchComponent.querySelector('.js-notify-text__container');

    if (!searchComponentTextContainer) {
      return;
    }

    searchComponentTextContainer.classList.add('js-hide');
  });
})();

// Footer - copyright year
(() => {
  const copyrightYear: HTMLElement | null = document.querySelector('.copyright-year');
  if (copyrightYear) {
    const currentYear: number = new Date().getFullYear();
    copyrightYear.textContent = currentYear.toString();
  }
})();
